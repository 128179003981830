// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".forms-module__myProjectsWrapper--wX46E{height:calc(100% - 64px)}.forms-module__myProjectsWrapper--wX46E .dropzone{height:100%}.forms-module__myProjectsWrapper--wX46E .asset-list--fixed-headings .asset-list-sorts{margin-top:62px}.forms-module__myProjectsHeader--oMzpW:not(:empty){padding:30px 30px 0}", "",{"version":3,"sources":["webpack://./jsapp/js/lists/forms.module.scss"],"names":[],"mappings":"AAKA,wCACE,wBAAA,CACA,kDACE,WAAA,CAEF,sFACE,eAAA,CAIJ,mDACE,mBAAA","sourcesContent":["@use 'scss/sizes';\n\n// NOTE: this whole wrapper is only a TEMP quick and imperfect fix. We need it\n// here so the UI behaves as it was supposed to. In upcoming work the whole\n// section would be rewritten, so there is no need to be fancy.\n.myProjectsWrapper {\n  height: calc(100% - 64px);\n  :global .dropzone {\n    height: 100%;\n  }\n  :global .asset-list--fixed-headings .asset-list-sorts {\n    margin-top: 62px;\n  }\n}\n\n.myProjectsHeader:not(:empty) {\n  padding: sizes.$x30 sizes.$x30 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"myProjectsWrapper": "forms-module__myProjectsWrapper--wX46E",
	"myProjectsHeader": "forms-module__myProjectsHeader--oMzpW"
};
export default ___CSS_LOADER_EXPORT___;
